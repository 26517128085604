var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
    "id": "99a6bfe334e05f822c7f392510d722549080b39b"
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/nhp";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";
import { shouldExcludeMonitoring, shouldRemoveTrackers } from "utils/general";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
(!shouldRemoveTrackers() || !shouldExcludeMonitoring()) && Sentry.init({
    dsn: SENTRY_DSN || "https://0a9f00c4a3c746ce8aac9e2c5e612674@o916138.ingest.sentry.io/6123979",
    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [
        new BrowserTracing()
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    release: process.env.CF_REVISION
});
